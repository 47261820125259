<template>
  <div v-if="!isFetching">
    <h1>Pokemon</h1>
    <div class="block">
      <h2>What is good against ...</h2>
      <div
        @click="toggleDiv"
        :class="{
          'type-icon': selectedType !== 'Please select',
          [selectedType]: 'true',
        }"
      >
        {{ selectedType }}
      </div>

      <ul v-if="!showSelect">
        <li
          class="type-icon"
          :class="name"
          v-for="(value, name) in types"
          :key="value"
          @click="selectType(name)"
        >
          {{ name }}
        </li>
      </ul>
      <div v-if="selectedType !== 'Please select'">
        <div
          v-if="
            typeChart[selectedType] && typeChart[selectedType].veryEffective
          "
        >
          <h3>Super effective:</h3>
          <div
            class="type-icon"
            :class="name"
            v-for="(value, name) in typeChart[selectedType].veryEffective"
            :key="value"
          >
            {{ name }}
          </div>
        </div>

        <div
          v-if="typeChart[selectedType] && typeChart[selectedType].effective"
        >
          <h3>Effective:</h3>
          <div class="switcher">
            <div
              class="type-icon"
              :class="name"
              v-for="(value, name) in typeChart[selectedType].effective"
              :key="value"
            >
              {{ name }}
            </div>
          </div>
        </div>

        <div
          v-if="
            typeChart[selectedType] && typeChart[selectedType].notVeryEffective
          "
        >
          <h3>Not very effective:</h3>
          <div
            class="type-icon"
            :class="name"
            v-for="(value, name) in typeChart[selectedType].notVeryEffective"
            :key="value"
          >
            {{ name }}
          </div>
        </div>

        <div v-if="typeChart[selectedType] && typeChart[selectedType].noEffect">
          <h3>No effect:</h3>
          <div
            class="type-icon"
            :class="name"
            v-for="(value, name) in typeChart[selectedType].noEffect"
            :key="value"
          >
            {{ name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = "true";

export default {
  name: "App",
  data() {
    return {
      isFetching: true,
      showSelect: true,
      selectedType: "Please select",
      types: {},
      typeChart: {},
    };
  },
  created() {
    this.checklogin();
  },
  methods: {
    async checklogin() {
      const specific_date = Date.now();
      const current_date = localStorage.getItem("sha1");
      if (current_date > specific_date) {
        this.isFetching = false;
        const accessdata = {
          withCredentials: true,
        };
        try {
          const res = await axios.post(
            // "http://localhost/pokemon/api/?typeChart",
            "https://pokemon.spacekit.de/api/?typeChart",
            accessdata,
            {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
              },
            }
          );

          if (res.status === 200) {
            this.typeChart = res.data;
            this.types = res.data;
          } else {
            window.location.href = "/login";
          }
        } catch (e) {
          console.error(e);
          window.location.href = "/login";
        }
      } else {
        window.location.href = "/login";
      }
    },
    toggleDiv: function () {
      this.showSelect = !this.showSelect;
      if (!this.showSelect) {
        this.selectedType = "Please select";
      }
    },
    selectType: function (type) {
      this.selectedType = type;
      this.toggleDiv();
    },
  },
};
</script>

<style>
ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.type-icon {
  display: inline-block;
  width: 66px;
  margin: 4px;
  background: #dbdbdb;
  border: 1px solid #a3a3a3;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1.5rem;
  text-align: center;
  text-shadow: 1px 1px 2px rgb(0 0 0 / 70%);
  text-transform: uppercase;
  transition: opacity 0.4s;
}

.normal {
  background-color: #aa9;
}

.fire {
  background-color: #f42;
}

.water {
  background-color: #39f;
}

.electric {
  background-color: #fc3;
}

.grass {
  background-color: #7c5;
}

.ice {
  background-color: #6cf;
}

.fighting {
  background-color: #b54;
}

.poison {
  background-color: #a59;
}

.ground {
  background-color: #db5;
}

.flying {
  background-color: #89f;
}

.psychic {
  background-color: #f59;
}

.bug {
  background-color: #ab2;
}

.rock {
  background-color: #ba6;
}

.ghost {
  background-color: #66b;
}

.dragon {
  background-color: #76e;
}

.dark {
  background-color: #754;
}

.steel {
  background-color: #aab;
}

.fairy {
  background-color: #e9e;
}

/* .switcher {
  display: flex;
  flex-wrap: wrap;
  gap: 0.1em;
}

.switcher > * {
  flex-grow: 1;
  flex-basis: calc((66px - 100%) * 999);
} */
</style>
